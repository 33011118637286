const datasimling = [
  {
    id: "01",
    unit: "DKI",
    title: "SIMLING JAKTIM BUS 01",
    location: ["Grand Mall Cakung"],
    rute: "https://maps.app.goo.gl/gpMUJqqn3iMFZnAe8",
  },
  {
    id: "02",
    title: "SIMLING JAKUT BUS 02",
    location: ["Ltc Glodok, Jakarta Barat"],
    rute: "https://maps.app.goo.gl/72P2PEKqQKNeGdRK9",
  },
  {
    id: "03",
    title: "SIMLING JAKSEL BUS 03 ",
    location: ["Kampus Trilogi Kalibata, Jakarta Selatan"],
    rute: "https://maps.app.goo.gl/uCEwW2nN4of3a5DT9",
  },
  {
    id: "04",
    title: "SIMLING JAKBAR BUS 04",
    location: ["Mall Citraland, Jakarta Barat"],
    rute: "https://maps.app.goo.gl/4zK1ScKnzQ3G5zXD6",
  },
  {
    id: "05",
    title: "SIMLING JAKPUS BUS 05",
    location: ["Kantor Pos Dan Giro Pasar Baru, Jakarta Pusat"],
    rute: "https://maps.app.goo.gl/99tBTtXpsifeVa6j9",
  },
  {
    id: "06",
    unit: "BEKASI KOTA",
    title: "SIMLING BEKASI KOTA",
    location: [
      "Mitra10 Harapan Indah, Polsek  Metropolitan Mall Bekasi, Bekasi Cyberpark, Mitra10 Jati Makmur, Kantor Kec Bekasi Barat",
    ],
    rute: "https://maps.app.goo.gl/ZC1D6RfiW7XbDTE66",
  },
  {
    id: "07",
    unit: "TANGERANG SELATAN",
    title: "SIMLING BUS TANGSEL 1",
    location: ["Pamulang Square Dan Itc Bsd"],
    rute: "https://maps.app.goo.gl/2BZkkmJ5VToMq7LG9",
  },
  {
    id: "08",
    title: "SIMLING BUS TANGSEL 2",
    location: ["Itc Bsd Dan Bintaro Plaza"],
    rute: "https://maps.app.goo.gl/HQzYRSyVdb1KHFcX8",
  },
  {
    id: "09",
    unit: "TANGERANG KOTA",
    title: "SIMLING BUS TANGKOT 1",
    location: [
      "Plaza Shinta, Jl.teuku Umar No.1 Cimone Jaya Karawaci Tangerang Banten",
      "Pasar Modern Graha Raya, Jl.boulevard Graha Raya Kunciran Indah Pinang Tangerang",
      "Pasar Laris Taman Cibodas, Jl.raya Taman Cibodas Sangiang Jaya Periuk Tangerang",
      "Mall Metropolis, Jl.hartono Raya No.16 Kelapa Indah Modernland Cikokol Tangerang ",
      "Ruko Wom Finance",
    ],
    rute: "https://maps.app.goo.gl/PKPCwim28Sc84iC47",
  },
  {
    id: "10",
    title: "SIMLING BUS TANGKOT 2",
    location: [
      "Ruko Wom Finance",
      "Plaza Shinta, Jl.teuku Umar No.1 Cimone Jaya Karawaci Tangerang Banten",
      "Ikea Alam Sutra",
      "Mc Donald`s Jatake",
      "Pasar Laris Taman Cibodas, Jl.raya Taman Cibodas Sangiang Jaya Periuk Tangerang",
      "Living Plaza Palem Semi",
    ],
    rute: "https://maps.app.goo.gl/kyQ22oWMvJWSrnbV6",
  },
];

export default datasimling;
