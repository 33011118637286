/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import heroImg from "../../assets/images/psisim.png";
import wave from "../../assets/images/wave.svg";

const Hero = () => {
  return (
    <>
      <section className="pt-0" id="about">
        <div className="container mx-auto pt-14">
          <div className="md:flex items-center justify-between sm:flex-col md:flex-row">
            {/* === hero left content === */}
            <div className="w-full md:basis-1/2">
              <h5
                data-aos="fade-right"
                data-aos-duration="1500"
                className="text-headingColor font-[600] text-[16px]"
              >
                Selamat Datang
              </h5>
              <h1
                data-aos="fade-up"
                data-aos-duration="1500"
                className="text-headingColor font-[800] text-[1.8rem] sm:text-[40ox] leading-[35px] sm:leading-[46px] mt-5"
              >
                <span className="text-textSecondary">psisimonline.id</span>{" "}
                <br />
                PSIKOLOGI<span className="text-textSecondary"> SIM</span> ONLINE
              </h1>

              <div
                className="flex items-center gap-6 mt-7"
                data-aos="fade-up"
                data-aos-duration="1800"
                data-aos-delay="200"
              >
                <a href="https://tesman.psisimonline.id">
                  <button className="bg-headerColor text-white font-[500] flex items-center gap-2 hover:bg-[#25225e]/80 ease-in duration-300 py-2 px-4 rounded-[8px]">
                    <i className="ri-a-b"></i>Test Sekarang
                  </button>
                </a>
                <a
                  href="#services"
                  className="text-smallColor font-[600] text-[16px] border-b border-solid border-smallTextColor"
                >
                  Alur Tes
                </a>
              </div>
              <p
                data-aos="fade-left"
                data-aos-duration="1500"
                className="flex gap-2 text-headingColor font-[500] mt-14 text-[15px] leading-7 sm:pl-14 sm:pr-10 text-justify"
              >
                <span>
                  <i className="ri-apps-line"></i>
                </span>
                Tes Psikologi SIM digunakan untuk mengukur kesesuaian kinerja
                seseorang dari sisi psikologis individu baik dalam rentang waktu
                dan situasi apapun ketika berkendara, dengan kebutuhan perilaku
                berkendara sesuai dengan prosedur yang berlaku. Tes atau
                Pemeriksaan ini berbeda dengan ujian-ujian pada umumnya. Hasil
                pemeriksaan ini penting bagi Anda, karena Anda akan mengetahui
                kelemahan dan kemampuan diri Anda yang dapat memenuhi
                persyaratan Kesehatan Rohani untuk memiliki SIM.
              </p>

              <div className="flex items-center gap-9 mt-14">
                <span className="text-smallTextColor text-[15px] font-[600]">
                  Follow Us:
                </span>
                <span>
                  <a
                    target="_blank"
                    href="#youtube"
                    className="text-smallTextColor text-[16px] font-[600]"
                    rel="noreferrer"
                  >
                    <i className="ri-youtube-line"></i>
                  </a>
                </span>
                <span>
                  <a
                    target="_blank"
                    href="#facebook"
                    className="text-smallTextColor text-[16px] font-[600]"
                    rel="noreferrer"
                  >
                    <i className="ri-facebook-line"></i>
                  </a>
                </span>
                <span>
                  <a
                    target="_blank"
                    href="#instagram"
                    className="text-smallTextColor text-[16px] font-[600]"
                    rel="noreferrer"
                  >
                    <i className="ri-instagram-line"></i>
                  </a>
                </span>
              </div>
            </div>
            {/* === hero left end === */}
            {/* === hero img === */}
            <div className="basis-1/2 mt-10 sm:mt-0">
              <figure className="flex items-center justify-center">
                <img className="rounded-[50px]" src={heroImg} alt="" />
              </figure>
            </div>
            {/* === hero img end === */}
          </div>
        </div>
      </section>
      <img src={wave} />
    </>
  );
};

export default Hero;
