/* eslint-disable jsx-a11y/alt-text */
import React from "react";

const About = () => {
  return (
    <div>
      <div className="bg-sky-50 w-full">
        <div className="container mx-auto">
          <div className="grid gap-5 grid-cols-1 lg:grid-cols-3">
            <div className="bg-white shadow rounded">
              <div className="text-center mt-3">
                <i className="ri-device-line text-6xl text-gray-400"></i>
              </div>
              <h1 className="text-headerColor font-[600] text-[1.5rem] mb-8 text-center mt-2 mx-2">
                Lebih Praktis
              </h1>
              <p className="text-center mb-2 mx-2">
                Tes Psikologi <b>SIM di PSI SIM ONLINE</b> dilakukan menggunakan
                handphone melalui tes online yang disediakan.
              </p>
            </div>
            <div className="bg-white shadow rounded">
              <div className="text-center mt-3">
                <i className="ri-time-line text-6xl text-gray-400"></i>
              </div>
              <h1 className="text-headerColor font-[600] text-[1.5rem] mb-8 text-center mt-2 mx-2">
                Lebih Cepat
              </h1>
              <p className="text-center mb-2 mx-2">
                Tidak memakan waktu yang lama tes psikologi sudah bisa digunakan
                untuk melanjutkan pelayanan sim
              </p>
            </div>
            <div className="bg-white shadow rounded">
              <div className="text-center mt-3 text-6xl text-gray-400">
                <i className="ri-shake-hands-line"></i>
              </div>
              <h1 className="text-headerColor font-[600] text-[1.5rem] mb-8 text-center mt-2 mx-2">
                Lebih Akurat
              </h1>
              <p className="text-center mb-2 mx-2">
                Hasil Tes PSIKOLOGI SIM yang sudah memenuhi syarat dapat diunduh
                secara online
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
