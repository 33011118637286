const datasatpas = [
  {
    id: "01",
    unit: "DKI",
    title: "SATPAS DAANMOGOT",
    location: "Jl. Daan Mogot Raya Km 11 Cengkareng, Jakarta Barat",
    rute: "https://maps.app.goo.gl/aUfR9JF3DJpzEHL18",
  },
  {
    id: "02",
    title: "SATPAS JAKARTA BARAT",
    location: "Jl. Daan Mogot Raya Km 11 Cengkareng, Jakarta Barat",
    rute: "https://maps.app.goo.gl/aUfR9JF3DJpzEHL18",
  },
  {
    id: "03",
    title: "SATPAS JAKARTA PUSAT",
    location: "Jl. Apron No.1 Rt.15/Rw.05 Kemayoran Jakarta Pusat",
    rute: "https://maps.app.goo.gl/F6kyjuE29YLNfdCXA",
  },
  {
    id: "04",
    title: "SATPAS JAKARTA UTARA",
    location:
      "Jl. Gorontalo Raya No.80, Rt.03/Rw.01, Sungai Bambu, Tj. Priok, Jakarta Utara",
    rute: "https://maps.app.goo.gl/uaf5ZHLR3J4KkDcs7",
  },
  {
    id: "05",
    title: "SATPAS JAKARTA SELATAN",
    location:
      "Jl. Dharma Wangsa No.7, Rt.2/Rw.1, Pulo, Kebayoran Baru, Jakarta Selatan",
    rute: "https://maps.app.goo.gl/HUZovArdKuHgetzWA",
  },
  {
    id: "06",
    title: "SATPAS JAKARTA TIMUR",
    location:
      "Jl. Di. Panjaitan No.10, Rt.12/Rw.6, Cipinang Besar Sel., Kecamatan Jatinegara, Kota Jakarta Timur",
    rute: "https://maps.app.goo.gl/xBG4obomzhmaoEHE7",
  },
  {
    id: "07",
    unit: "BEKASI KOTA",
    title: "SATPAS POLRESTRO BEKASI KOTA (1220)",
    location: "Jl. Pramuka No.79, Rt.001/Rw.002, Marga Jaya Bekasi Selatan",
    rute: "https://maps.app.goo.gl/Vkr1Y2nQxtxZMnD7A",
  },
  {
    id: "08",
    unit: "BEKASI KABUPATEN",
    title: "SATPAS DELTAMAS",
    location:
      "Jl. Wibawa Mukti, Desa. Sukamahi, Kec. Cikarang Pusat, Kabupaten Bekasi, Jawa Barat 17530",
    rute: "https://maps.app.goo.gl/ckPdYnWcp8CLHSjF7",
  },
  {
    id: "09",
    title: "SATPAS KALIMALANG",
    location:
      "Jl. Inspeksi Kalimalang, Wangunharja, Kec. Cikarang Utara, Kabupaten Bekasi, Jawa Barat 17530",
    rute: "https://maps.app.goo.gl/b3S1hCd3jwrCmZCg7",
  },
  {
    id: "10",
    unit: "TANGERANG SELATAN",
    title: "SATPAS TANGERANG SELATAN",
    location: "Jl. Raya Serpong, Serpong, Kec. Serpong, Kota Tangerang Selatan",
    rute: "https://maps.app.goo.gl/H1s5kcNqB85iuU6q6",
  },
  {
    id: "11",
    unit: "TANGERANG KOTA",
    title: "SATPAS TANGERANG KOTA",
    location: "Jl. Daan Mogot No.52 Sukarasa Tangerang Banten",
    rute: "https://maps.app.goo.gl/RdwY75tTUrPiBfeU7",
  },
  {
    id: "12",
    unit: "DEPOK",
    title: "SATPAS POLRESTRO DEPOK 1221",
    location: "Jl. Margonda Raya No.14 Kota Depok",
    rute: "https://maps.app.goo.gl/YaF429NwiRnjMfLc9",
  },
  {
    id: "13",
    title: "SATPAS PASAR SEGAR",
    location: "Jl. Tole Iskandar, Blok Raden Saleh No.59 Depok",
    rute: "https://maps.app.goo.gl/7rS26mA3M5eCcuQk9",
  },
  {
    id: "14",
    title: "SATPAS CINERE",
    location: "Pasar Segar Cinere, Jl Cinere Raya No. 66, Limo, Kota Depok",
    rute: "https://maps.app.goo.gl/c6v1ECYLdJpiYPmv6",
  },
];

export default datasatpas;
