const datagerai = [
  {
    id: "01",
    unit: "DKI",
    title: "GERAI SIM MALL TAMAN PALEM",
    location: "Jl. Kamal Raya Outer Ring Road Cengkareng, Jakarta Barat",
    rute: "https://maps.app.goo.gl/wudK5EwvQQzPKHuF9",
  },
  {
    id: "02",
    title: "GERAI SIM LIPPO PURI MALL",
    location:
      "Jl. Puri Indah Raya Blok U 1, Puri Indah Cbd, Kembangan, Jakarta Barat",
    rute: "https://maps.app.goo.gl/GzzckRPzuPrRt7qN7",
  },
  {
    id: "03",
    title: "GERAI SIM GANDARIA CITY MALL",
    location: "Jl. Sultan Iskandar Muda, Keb. Lama, Jakarta Selatan",
    rute: "https://maps.app.goo.gl/3VT3bYganRj69UeE6",
  },
  {
    id: "04",
    title: "GERAI SIM KOJA",
    location: "Pasar Koja Baru, Jl Bhayangkara No.2 Koja, Jakarta Utara",
    rute: "https://maps.app.goo.gl/fUBbTnN9PB69E5XW6",
  },
  {
    id: "05",
    title: "GERAI SIM PLUIT VILLAGE",
    location: "Jl. Pluit Indah Raya, Penjaringan, Jakarta Utara",
    rute: "https://maps.app.goo.gl/1VZbodBbRJBUGfn27",
  },
  {
    id: "06",
    unit: "BEKASI KOTA",
    title: "GERAI SIM MPP BEKASI KOTA",
    location:
      "Btc Mal, Jl. Hm. Joyo Martono No.42 De, Rt.003/Rw.021, Margahayu, Bekasi Timur",
    rute: "https://maps.app.goo.gl/R4VxeWDqi1H3hk4i9",
  },
  {
    id: "07",
    unit: "TANGERANG SELATAN",
    title: "GERAI MALL SDC",
    location:
      "Summarecon Digital Center, Curug Sangereng, Kec. Klp Dua, Tangerang",
    rute: "https://maps.app.goo.gl/FpiJqM1eUu9d7A2t6",
  },
  {
    id: "08",
    unit: "TANGERANG KOTA",
    title: "GERAI MALL ALAM SUTRA",
    location:
      "Jl. Jalur Sutera Barat No.16 Panunggangan Pinang Tangerang Banten",
    rute: "https://maps.app.goo.gl/pa7GEYbcg2PZ4P64A",
  },
  {
    id: "09",
    title: "GERAI TANG CITY MALL",
    location: "Jl. Jenderal Sudirman No.1 Cikokol Tangerang Banten",
    rute: "https://maps.app.goo.gl/yVv1wVk6VJa61k9E9",
  },
  {
    id: "10",
    unit: "DEPOK",
    title: "GERAI SIM TSM CIBUBUR",
    location:
      "Jl. Mahogany Raya No.5, Harjamukti, Kec. Cimanggis, Kota Depok, Jawa Barat 16454",
    rute: "https://maps.app.goo.gl/o2oeB5gyir1dAZ9P9",
  },
];

export default datagerai;
