/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import s from "../../assets/data/DataSatpas";
import Korlantas from "../../assets/images/logo-korlantas.png";
import { FaDirections } from "react-icons/fa";
import Satpas from "./LokasiPelayanan/Satpas";
import Gerai from "./LokasiPelayanan/Gerai";
import Simling from "./LokasiPelayanan/Simling";

const LokasiPelayanan = () => {
  const [nextItems, setNextItem] = useState(6);
  const [dataSatpas, setDataSatpas] = useState(s);

  useEffect(() => {
    setDataSatpas(s);
  }, []);

  const loadMoreHandler = () => {
    setNextItem((prev) => prev + 3);
  };

  return (
    <>
      <section id="lokasi-pelayanan" className="-mt-20">
        <div className="container mx-auto">
          <div className="text-center">
            <h2 className="text-headingColor font-[800] text-[2.4rem] mb-5">
              Lokasi Pelayanan
            </h2>
            <p className="lg:max-w-[600px] lg:mx-auto text-headingColor font-[500] text-[16px] leading-7 mb-10">
              Lokasi Dan Rute Pelayanan PSIKOLOGI
            </p>
          </div>
          <div className="grid gap-5 grid-cols-1 lg:grid-cols-3">
            <div className="bg-[#dcfce7] shadow rounded mb-20 p-2">
              <Satpas />
            </div>
            <div className="bg-[#cffafe] shadow rounded mb-20 p-2">
              <Gerai />
            </div>
            <div className="bg-[#dbeafe] shadow rounded mb-20 p-2">
              <Simling />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default LokasiPelayanan;
