import React, { useEffect, useState } from "react";
import sml from "../../../assets/data/DataSimling";

import { FaDirections } from "react-icons/fa";

const Simling = () => {
  const [nextItems, setNextItem] = useState(3);
  const [dataSimling, setDataSimling] = useState(sml);

  useEffect(() => {
    setDataSimling(sml);
  }, []);

  const loadMoreHandler = () => {
    setNextItem((prev) => prev + 3);
  };

  return (
    <>
      <div className="text-center text-2xl font-semibold">SIMLING</div>
      <div className="mb-5">
        {dataSimling?.slice(0, nextItems)?.map((sml, index) => (
          <div key={index}>
            <h2 className="mb-2 text-lg font-semibold">{sml.unit}</h2>
            <div className="mb-5">
              <ul className="space-y-4 list-disc list-inside">
                <li>
                  {sml.title}
                  <ol className="pl-5 mt-2 space-y-1 list-decimal list-inside">
                    <div>
                      {sml.location.map((item, index) => (
                        <span
                          key={index}
                          className="flex flex-col justify-between"
                        >
                          {item}
                        </span>
                      ))}
                    </div>
                    <div>
                      <a target="_blank" href={sml.rute} rel="noreferrer">
                        <button className="bg-headerColor text-white font-[500] flex items-center gap-2 hover:bg-textSecondary/80 ease-in duration-300 py-1 px-2 rounded-[8px]">
                          <FaDirections /> Rute
                        </button>
                      </a>
                    </div>
                  </ol>
                </li>
              </ul>
            </div>
          </div>
        ))}
      </div>
      <div className="text-center mt-6">
        {nextItems < dataSimling.length && sml.length > 3 && (
          <button
            onClick={loadMoreHandler}
            className="text-white bg-textSecondary hover:bg-headerColor py-2 px-4 rounded-[8px] font-[500] ease-in duration-200"
          >
            Load More
          </button>
        )}
      </div>
    </>
  );
};

export default Simling;
