import React, { useRef, useEffect } from "react";
import heroImg from "../../assets/images/logo-psi.svg";

const Header = () => {
  const headerRef = useRef(null);
  const menuRef = useRef(null);

  const styckyHeaderFunc = () => {
    window.addEventListener("scroll", () => {
      if (
        document.body.scrollTop > 80 ||
        document.documentElement.scrollTop > 80
      ) {
        headerRef.current.classList.add("sticky__header");
      } else {
        headerRef.current.classList.add("sticky__header");
      }
    });
  };

  useEffect(() => {
    styckyHeaderFunc();

    return window.removeEventListener("scroll", styckyHeaderFunc);
  }, []);

  const handleClick = (e) => {
    e.preventDefault();

    const targetAttr = e.target.getAttribute("href");
    const location = document.querySelector(targetAttr).offsetTop;

    window.scrollTo({
      top: location - 80,
      left: 0,
    });
  };

  const toggleMenu = () => menuRef.current.classList.toggle("show__menu");

  return (
    <header
      ref={headerRef}
      className="w-full h-[80px] leading-[80px] flex items-center bg-[#2563eb] sticky__header"
    >
      <div className="container mx-auto">
        <div className="flex justify-between">
          {/* === logo === */}
          <div className="flex items-center gap-[10px]">
            <figure className="w-12 h-12">
              <a href="/">
                <img src={heroImg} alt="" />
              </a>
            </figure>
          </div>

          {/* === logo end === */}
          {/* === menu start === */}
          <div className="flex gap-10">
            <div className="menu" ref={menuRef} onClick={toggleMenu}>
              <ul className="flex gap-10">
                <li>
                  <a
                    onClick={handleClick}
                    className="text-white font-[600]"
                    href="#about"
                  >
                    Home
                  </a>
                </li>
                <li>
                  <a
                    onClick={handleClick}
                    className="text-white font-[600]"
                    href="#services"
                  >
                    Alur Tes
                  </a>
                </li>
                <li>
                  <a
                    onClick={handleClick}
                    className="text-white font-[600]"
                    href="#lokasi-pelayanan"
                  >
                    Lokasi Pelayanan
                  </a>
                </li>
              </ul>
            </div>
            {/* === menu end === */}

            {/* === menu right === */}
            <div className="flex gap-2 items-center">
              <a
                target="_blank"
                href="https://wa.me/+628118069890?text=Hallo%20admin%20psi%20sim%20online..."
                className="flex items-center gap-2 text-white font-[600] border border-solid border-white py-2 px-4 rounded-[8px] max-h-[40px] hover:bg-primaryColor hover:text-white hover:font-[500] ease-in duration-300"
                rel="noreferrer"
              >
                <i className="ri-whatsapp-line"></i>Contact Us
              </a>
              <span
                onClick={toggleMenu}
                className="text-2xl text-white md:hidden"
              >
                <i className="ri-menu-line md:mx-96"></i>
              </span>
            </div>
          </div>
          {/* === menu end */}
        </div>
      </div>
    </header>
  );
};

export default Header;
