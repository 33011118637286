import React from "react";

const Footer = () => {
  return (
    <footer className="bg-[#12141e] pt-12 pb-12">
      {/* === footer top === */}
      <div className="container mx-auto">
        <div className="sm:flex items-center justify-between mg:gap-8">
          <div className="">
            <h2 className="text-[26px] leading-10 text-white font-[600] md-5 md:text-[2rem] pb-5">
              Apakah Anda sudah melakukan Tes PSIKOLOGI?
            </h2>
            <a
              target="_blank"
              href="https://tesman.psisimonline.id"
              rel="noreferrer"
            >
              <button className="bg-headerColor text-white font-[500] flex items-center gap-2 hover:bg-textSecondary/80 ease-in duration-300 py-2 px-4 rounded-[8px]">
                <i className="ri-a-b"></i>Tes Sekarang
              </button>
            </a>
          </div>

          <div className="w-full sm:w-1/2">
            <p className="text-gray-300 leading-7 mt-4 sm:mt-0 ">
              .
              <br />
              Email: support@psisimonline.id
              <br />
              WA: +628-1180-69890
            </p>

            <div className="flex items-center gap-4 flex-wrap md-gap-8 mt-10">
              <span className="text-gray-300 font-600 text-[15px]">
                Follow Us:
              </span>
              <span className="w-[35px] h-[35px] bg-[#2b2d33] p-1 rounded-[50px] cursor-pointer text-center">
                <a
                  href="#youtube"
                  className="text-gray-300 font-[500] text-[18px]"
                >
                  <i className="ri-youtube-line"></i>
                </a>
              </span>
              <span className="w-[35px] h-[35px] bg-[#2b2d33] p-1 rounded-[50px] cursor-pointer text-center">
                <a
                  href="#facebook"
                  className="text-gray-300 font-[500] text-[18px]"
                >
                  <i className="ri-facebook-line"></i>
                </a>
              </span>
              <span className="w-[35px] h-[35px] bg-[#2b2d33] p-1 rounded-[50px] cursor-pointer text-center">
                <a
                  target="_blank"
                  href="#instagram"
                  className="text-gray-300 font-[500] text-[18px]"
                  rel="noreferrer"
                >
                  <i className="ri-instagram-line"></i>
                </a>
              </span>
            </div>
          </div>
        </div>
        <div>
          <ul className="flex items-center justify-center gap-10 mt-10">
            <li>
              <a className="text-gray-400 font-[600]" href="#about">
                Home
              </a>
            </li>
            <li>
              <a className="text-gray-400 font-[600]" href="#services">
                Alur Tes
              </a>
            </li>
            <li>
              <a className="text-gray-400 font-[600]" href="#lokasi-pelayanan">
                Lokasi Layanan
              </a>
            </li>
            <li>
              <a
                className="text-gray-400 font-[600]"
                href="https://wa.me/+628118069890?text=Hallo%20admin%20psi%20sim%20online..."
              >
                Contact
              </a>
            </li>
          </ul>
        </div>
      </div>
      {/* === footer top end === */}
      {/* === footer bottom === */}
      <div className="bg-headerColor py-3 mt-14">
        <div className="container mx-auto">
          <div className="text-gray-300 text-[14px] text-center">
            Copyright 2022 developed by IT Psisimonline.id - All right reserved.
          </div>
        </div>
      </div>
      {/* ===footer bottom end === */}
    </footer>
  );
};

export default Footer;
