import React, { useEffect, useState } from "react";
import g from "../../../assets/data/DataGerai";

import { FaDirections } from "react-icons/fa";

const Gerai = () => {
  const [nextItems, setNextItem] = useState(3);
  const [dataGerai, setDataGerai] = useState(g);

  useEffect(() => {
    setDataGerai(g);
  }, []);

  const loadMoreHandler = () => {
    setNextItem((prev) => prev + 3);
  };

  return (
    <>
      <div className="text-center text-2xl font-semibold">GERAI</div>
      <div className="mb-5">
        {dataGerai?.slice(0, nextItems)?.map((s, index) => (
          <div key={index}>
            <h2 className="mb-2 text-lg font-semibold">{s.unit}</h2>
            <div className="mb-5">
              <ul className="space-y-4 list-disc list-inside">
                <li>
                  {s.title}
                  <ol className="pl-5 mt-2 space-y-1 list-decimal list-inside">
                    <p>{s.location}</p>
                    <div>
                      <a target="_blank" href={s.rute} rel="noreferrer">
                        <button className="bg-headerColor text-white font-[500] flex items-center gap-2 hover:bg-textSecondary/80 ease-in duration-300 py-1 px-2 rounded-[8px]">
                          <FaDirections /> Rute
                        </button>
                      </a>
                    </div>
                  </ol>
                </li>
              </ul>
            </div>
          </div>
        ))}
      </div>
      <div className="text-center mt-6">
        {nextItems < dataGerai.length && g.length > 3 && (
          <button
            onClick={loadMoreHandler}
            className="text-white bg-textSecondary hover:bg-headerColor py-2 px-4 rounded-[8px] font-[500] ease-in duration-200"
          >
            Load More
          </button>
        )}
      </div>
    </>
  );
};

export default Gerai;
